import React from 'react';
import Home from './Components/Home';

export default function App() {

  return (
    <div>  
      <Home/>
    </div>
  );
}


